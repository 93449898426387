import { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { withPrefix, Script } from "gatsby";
import { CartContext } from "context/CartContext";
import Footer from "components/Footer";
import Navbar from "components/Navbar";
import useGlobal from "store";

import { getCheckoutToken } from "utils/getCheckoutToken";

import "styles/all.sass";
import "styles/custom.css";
import "styles/custom2.css";

const Layout = ({ children }) => {
  const [globalState] = useGlobal();
  const [loaded, setLoaded] = useState(false);

  const [getCart, { data }] = useLazyQuery(CART, {
    variables: { id: globalState.cartID },
    fetchPolicy: "cache-and-network",
  });

  // useEffect(() => {
  //   if (loaded) {
  //     window?.pushowl?.trigger("getCurrentPermission").then((permission) => {
  //       console.log("push");

  //       if (permission !== "default") return;
  //       window.pushowl.trigger("showWidget", { type: "browserPrompt" });
  //     });
  //   }
  // }, [loaded]);

  useEffect(() => {
    if (!data?.cart) return;
    window?.pushowl?.trigger("syncCart", {
      checkoutToken: getCheckoutToken(data.cart),
      items: data.cart.lines.edges.map(({ node }) => ({
        variantId: node.merchandise.id.slice(-14),
        productId: node.merchandise.product.id.slice(-13),
        quantity: node.quantity,
      })),
    });
  }, [data]);

  return (
    <>
      <Script
        src={withPrefix("scripts/pushOwl.js")}
        onLoad={() => setLoaded(true)}
      />
      <CartContext.Provider value={{ data, getCart }}>
        <div id="top">
          <Navbar />
          {children}
          <Footer />
        </div>
      </CartContext.Provider>
    </>
  );
};

export default Layout;

const CART = gql`
  query Cart($id: ID!) {
    cart(id: $id) {
      id
      checkoutUrl
      createdAt
      updatedAt
      lines(first: 100) {
        edges {
          node {
            id
            quantity
            merchandise {
              ... on ProductVariant {
                id
                title
                selectedOptions {
                  name
                  value
                }
                image {
                  id
                  url
                }
                price {
                  amount
                  currencyCode
                }
                product {
                  id
                  title
                }
              }
            }
          }
        }
      }
      attributes {
        key
        value
      }
      estimatedCost {
        totalAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        totalTaxAmount {
          amount
          currencyCode
        }
        totalDutyAmount {
          amount
          currencyCode
        }
      }
    }
  }
`;
